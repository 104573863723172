import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "../components/header/header"
import Seo from "../components/seo"
import Banner from "../components/home/banner"
import AboutSection from "../components/home/aboutSection"
import SocialBar from "../components/home/socialBar"
import NewsSectionHeader from "../components/home/newsSectionHeader"
import News from "../components/home/News"
import Footer from "../components/footer/Footer"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(isFrontPage: { eq: true }) {
        id
        content
        date
        title
        homeOptions {
          aboutButtonLabel
          aboutButtonUrl
          aboutColumnLeft
          aboutColumnRight
          aboutTitle
          facebookLink
          socialMediaTitle
          twitterLink
          newsSectionTitle
          newsSectionText
        }
        dateGmt
        featuredImage {
          node {
            id
            sourceUrl
            srcSet
            sizes
            altText
          }
        }
        seo {
          metaKeywords
          metaDesc
          schema {
            articleType
          }
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          title
          twitterDescription
          twitterTitle
          twitterImage {
            sourceUrl
          }
          opengraphImage {
            sourceUrl
          }
        }
      }
    }
  `)
  console.log(data)
  const post = data.wpPage
  const seo = post?.seo
  const excerpt = post?.excerpt
    ? post.excerpt.replace(/(<([^>]+)>)/gi, "")
    : post?.content.replace(/(<([^>]+)>)/gi, "").substring(0, 100)
  //string.substring(0, length)
  const twTitle = seo?.twitterTitle ? seo.twitterTitle : post.title
  const twDesc = seo?.twitterDescription ? seo.twitterDescription : excerpt
  const twImage = seo?.twitterImage?.sourceUrl
    ? seo.twitterImage.sourceUrl
    : post.featuredImage?.node.sourceUrl

  const fbTitle = seo?.opengraphTitle ? seo.opengraphTitle : post.title
  const fbDesc = seo?.opengraphDescription ? seo.opengraphDescription : excerpt
  const fbImage = seo?.opengraphImage?.sourceUrl
    ? seo.opengraphImage.sourceUrl
    : post.featuredImage?.node.sourceUrl
  return (
    <div className="home-wrapper">
      <Header />
      <Seo
        title={post.title}
        facebookImage={fbImage}
        twitterImage={twImage}
        twitterTitle={twTitle}
        facebookTitle={fbTitle}
        twitterDescription={twDesc.replace(/(<([^>]+)>)/gi, "")}
        facebookDescription={fbDesc.replace(/(<([^>]+)>)/gi, "")}
      />

      <Banner
        title={data.wpPage.title}
        content={data.wpPage.content}
        sourceUrl={data.wpPage.featuredImage.node.sourceUrl}
        sizes={data.wpPage.featuredImage.node.sizes}
        srcset={data.wpPage.featuredImage.node.srcSet}
      ></Banner>
      <AboutSection
        title={data.wpPage.homeOptions.aboutTitle}
        aboutColumnLeft={data.wpPage.homeOptions.aboutColumnLeft}
        aboutColumnRight={data.wpPage.homeOptions.aboutColumnRight}
        aboutButtonURL={data.wpPage.homeOptions.aboutButtonUrl}
        aboutButtonLabel={data.wpPage.homeOptions.aboutButtonLabel}
      ></AboutSection>
      <SocialBar
        title={data.wpPage.homeOptions.socialMediaTitle}
        facebookLink={data.wpPage.homeOptions.facebookLink}
        twitterLink={data.wpPage.homeOptions.twitterLink}
      ></SocialBar>
      <section className="news-section" id="news-section">
        <div className="container">
          <NewsSectionHeader
            title={data.wpPage.homeOptions.newsSectionTitle}
            text={data.wpPage.homeOptions.newsSectionText}
          ></NewsSectionHeader>
          <News></News>
          <div className="link-holder">
            <Link className="news-article__btn" to="/category/nyheter">
              Se alle
            </Link>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  )
}

export default IndexPage
