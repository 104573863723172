import PropTypes from "prop-types"
import React from "react"
import "./banner.scss"
//Images

const Banner = props => (
  <section className="main-banner">
    <img className="main-banner__image" src={props.sourceUrl} alt={props.alt} />
    <div className="container">
      <div className="main-banner__inner">
        <h1 className="main-banner__title">{props.title}</h1>
        <div
          className="main-banner__text"
          dangerouslySetInnerHTML={{ __html: props.content }}
        ></div>
      </div>
    </div>
  </section>
)

Banner.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  sourceUrl: PropTypes.string,
  srcset: PropTypes.string,
  sizes: PropTypes.string,
  alt: PropTypes.string,
}

export default Banner
