import PropTypes from "prop-types"
import React from "react"
import "./aboutSection.scss"
//Images

const AboutSection = props => (
  <section className="about-section">
    <div className="container has_gutter about_gutter">
      <h2 className="about-section__title">{props.title}</h2>
      <div className="row">
        <div className="column-6 column-mob-12">
          <div
            className="about-section__left"
            dangerouslySetInnerHTML={{ __html: props.aboutColumnLeft }}
          ></div>
        </div>
        <div className="column-6 column-mob-12">
          <div
            className="about-section__right"
            dangerouslySetInnerHTML={{ __html: props.aboutColumnRight }}
          ></div>
          <a className="about-section__btn btn" href={props.aboutButtonURL}>
            {props.aboutButtonLabel}
          </a>
        </div>
      </div>
    </div>
  </section>
)

AboutSection.propTypes = {
  title: PropTypes.string,
  aboutColumnLeft: PropTypes.string,
  aboutColumnRight: PropTypes.string,
  aboutButtonURL: PropTypes.string,
  aboutButtonLabel: PropTypes.string,
}

export default AboutSection
