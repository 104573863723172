import PropTypes from "prop-types"
import React from "react"
import "./socialBar.scss"
//Images

const SocialBar = props => (
  <section className="social-bar">
    <div className="container">
      <h2 className="social-bar__title">{props.title}</h2>
      <div className="social-bar__btns">
        <a
          href={props.twitterLink}
          rel="noreferrer noopener"
          target="_blank"
          className="social-btn social-btn--twitter"
        >
          Twitter
        </a>
        <a
          href={props.facebookLink}
          rel="noreferrer noopener"
          target="_blank"
          className="social-btn social-btn--facebook"
        >
          Facebook
        </a>
      </div>
    </div>
  </section>
)

SocialBar.propTypes = {
  title: PropTypes.string,
  facebookLink: PropTypes.string,
  twitterLink: PropTypes.string,
}

export default SocialBar
