import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import NewsColumn from "./newsColumn"

export const NewsList = props => {
  const data = useStaticQuery(graphql`
    {
      allWpPost(limit: 9) {
        nodes {
          title
          excerpt
          slug
          featuredImage {
            node {
              altText
              sizes
              sourceUrl
              srcSet
            }
          }
          categories {
            nodes {
              slug
            }
          }
        }
      }
    }
  `)

  const posts = data?.allWpPost?.nodes

  return posts.map(item => {
    return (
      <NewsColumn
        key={item.slug}
        title={item.title}
        excerpt={item.excerpt}
        slug={item.slug}
      >
        {" "}
      </NewsColumn>
    )
  })
}

export default NewsList
