import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import NewsCategories from "./newsCategories"
import NewsList from "./NewsList"
export const News = () => {
  const query = useStaticQuery(graphql`
    {
      allWpCategory {
        nodes {
          databaseId
          name
          slug
        }
      }
    }
  `)

  const cats = query.allWpCategory.nodes
  const [currentCat, setcurrentCat] = useState(null)

  return (
    <div>
      <NewsCategories
        cats={cats}
        onClick={setcurrentCat}
        current={currentCat}
      ></NewsCategories>
      <div className="news-wrapper news_gutter">
        <div className="row">
          <NewsList></NewsList>
        </div>
      </div>
    </div>
  )
}

export default News
